<template>
  <div>
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="6"
      >
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        md="6"
      >
        <v-card>
          <v-card-text>
            <p class="text-h5 text-center">
              <b>Facture n° {{ invoice.number }}</b>
            </p>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col
                cols="4"
                class="align-self-center"
              >
                <v-subheader>Apprenant :</v-subheader>
              </v-col>
              <v-col
                cols="8"
                class="align-self-center"
              >
                {{ invoice.student.nom }} {{ invoice.student.prenom }}
                <v-btn
                  class="mb-1 ml-2"
                  elevation="2"
                  color="primary"
                  icon
                  small
                  @click="goToStud"
                >
                  <v-icon>
                    {{ icons.mdiAccount }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col
                cols="4"
                class="align-self-center"
              >
                <v-subheader>Formation :</v-subheader>
              </v-col>
              <v-col
                cols="8"
                class="align-self-center"
              >
                {{ formation.nom }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col
                cols="4"
                class="align-self-center"
              >
                <v-subheader>Status :</v-subheader>
              </v-col>
              <v-col
                cols="8"
                class="align-self-center"
              >
                <FacturationStatusChipVue :status="invoice.status"></FacturationStatusChipVue>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col
                cols="4"
                class="align-self-center"
              >
                <v-subheader>Montant :</v-subheader>
              </v-col>
              <v-col
                cols="8"
                class="align-self-center"
              >
                {{ Number(invoice.total).toLocaleString('fr-FR') }} €
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="invoice.status !== null">
            <v-row class="d-flex justify-center">
              <v-col
                cols="4"
                class="align-self-center"
              >
                <v-subheader>Facturé le :</v-subheader>
              </v-col>
              <v-col
                cols="8"
                class="align-self-center"
              >
                <v-menu
                  v-model="menuInvoicingDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="niceInvoicingDate"
                      :append-icon="icons.mdiCalendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="InvoicingDate"
                    locale="fr"
                    @change="updateInvoicingDate"
                    @input="menuInvoicingDate = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="invoice.status === 'paiementrecu'">
            <v-row class="d-flex justify-center">
              <v-col
                cols="4"
                class="align-self-center"
              >
                <v-subheader>Payée le :</v-subheader>
              </v-col>
              <v-col
                cols="8"
                class="align-self-center"
              >
                <v-menu
                  v-model="menuRecievingDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="niceRecievingDate"
                      :error="invoice.datereception === null"
                      :append-icon="icons.mdiCalendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="RecievingDate"
                    locale="fr"
                    @change="updateRecievingDate"
                    @input="menuRecievingDate = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-btn
              v-if="invoice.status === null"
              block
              outlined
              color="success"
              class="mb-3"
              @click="invoiceSent"
            >
              Facture envoyée
            </v-btn>
            <v-btn
              v-if="invoice.status === 'facture'"
              block
              outlined
              color="success"
              class="mb-3"
              @click="paymentRecieved"
            >
              Paiement reçu
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-hover
          v-slot="{ hover }"
          open-delay="200"
        >
          <v-card
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            :elevation="hover ? 16 : 2"
          >
            <v-img src="@/assets/images/misc/capture-facture.jpg">
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex justify-center transition-fast-in-fast-out primary v-card--reveal"
                  style="height: 100%;"
                >
                  <v-btn
                    outlined
                    color="info"
                    class="ma-2 white--text align-self-center"
                    @click="goToFile"
                  >
                    Voir
                  </v-btn>
                  <v-btn
                    outlined
                    color="info"
                    class="ma-2 white--text align-self-center"
                    @click="downloadFile(invoice.pdf)"
                  >
                    Télécharger
                  </v-btn>
                </div>
              </v-expand-transition>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiEyeCircle, mdiCalendar, mdiAccount } from '@mdi/js'
import FacturationStatusChipVue from './FacturationStatusChip.vue'

export default {
  components: {
    FacturationStatusChipVue,
  },
  data() {
    return {
      loading: true,
      menuInvoicingDate: false,
      InvoicingDate: new Date(),
      niceInvoicingDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menuRecievingDate: false,
      RecievingDate: new Date(),
      niceRecievingDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      invoice: {},
      formation: {},
      review: {},
      icons: {
        mdiEyeCircle,
        mdiCalendar,
        mdiAccount,
      },
      statusList: [
        {
          statusName: null,
          text: 'En attente',
        },
        {
          statusName: 'afacturer',
          text: 'A facturer',
        },
        {
          statusName: 'facture',
          text: 'Facturé',
        },
        {
          statusName: 'paiementrecu',
          text: 'Paiement reçu',
        },
      ],
    }
  },
  computed: {
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      Promise.all([this.fetchInvoice(), this.fetchReviews()])
        .then(values => {
          [this.invoice, this.review] = values
          this.InvoicingDate = new Date(this.invoice.datefacturation).toISOString().substr(0, 10)
          this.niceInvoicingDate = this.parseDate(this.InvoicingDate)
          this.RecievingDate = new Date(this.invoice.datereception).toISOString().substr(0, 10)
          this.niceRecievingDate = this.parseDate(this.RecievingDate)
          this.$http.get(`${process.env.VUE_APP_API_URL}/formations/${this.invoice.student.formation}`)
            .then(res => { this.formation = res.data })
            .catch(err => console.log(err))
            .finally(
              this.loading = false,
            )
        })
        .catch(err => console.log(err))
        .finally(() => {
        })
    },
    async fetchInvoice() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/factures/${this.$route.params.id}`)

      return response.data
    },
    async fetchReviews() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/reviews/23`)

      return response.data
    },
    updateInvoicingDate() {
      this.niceInvoicingDate = this.parseDate(this.InvoicingDate)
      const datefacturation = new Date(this.InvoicingDate)
      this.$http
        .put(`${process.env.VUE_APP_API_URL}/factures/${this.invoice.id}`, {
          datefacturation,
        })
        .then(res => {
          this.invoice = res.data
        })
    },
    updateRecievingDate() {
      this.niceRecievingDate = this.parseDate(this.RecievingDate)
      const datereception = new Date(this.RecievingDate)
      this.$http
        .put(`${process.env.VUE_APP_API_URL}/factures/${this.invoice.id}`, {
          datereception,
        })
        .then(res => {
          this.invoice = res.data
        })
    },
    invoiceSent() {
      const datefacturation = new Date()
      const status = 'facture'
      this.$http
        .put(`${process.env.VUE_APP_API_URL}/factures/${this.invoice.id}`, {
          datefacturation,
          status,
        })
        .then(res => {
          console.log(res.data)
          this.invoice = res.data
        })
    },
    paymentRecieved() {
      const datereception = new Date()
      const status = 'paiementrecu'
      this.$http
        .put(`${process.env.VUE_APP_API_URL}/factures/${this.invoice.id}`, {
          datereception,
          status,
        })
        .then(res => {
          console.log(res.data)
          this.invoice = res.data
        })
    },
    parseDate(date) {
      return this.$date(new Date(date), 'dd MMMM yyy')
    },
    goToFile() {
      return window.open(`${process.env.VUE_APP_API_URL}${this.invoice.pdf.url}`)
    },
    downloadFile(item) {
      fetch(`${process.env.VUE_APP_API_URL + item.url}`)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Facture  - ${this.invoice.student.nom} ${this.invoice.student.prenom}`
          link.click()
        })
        .catch(console.error)
    },
    getUrl(url) {
      return `${process.env.VUE_APP_API_URL}${url}`
    },
    goToStud() {
      return this.$router.push({ name: 'student-single', params: { id: this.invoice.student.id } })
    },
  },
}
</script>

<style>

</style>
