<template>
  <v-chip
    :color="getStatus.color"
    outlined
    small
  >
    {{ getStatus.text }}
  </v-chip>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: 'afacturer',
    },
  },
  data() {
    return {
      statusList: [
        {
          statusName: null,
          text: 'En attente',
          color: '#26C6DA',
        },
        {
          statusName: 'afacturer',
          text: 'A facturer',
          color: '#FB8C00',
        },
        {
          statusName: 'facture',
          text: 'Facturé',
          color: '#5C6BC0',
        },
        {
          statusName: 'paiementrecu',
          text: 'Paiement reçu',
          color: 'success',
        },
      ],
    }
  },
  computed: {
    getStatus() {
      return this.statusList.find(x => x.statusName === this.status)
    },
  },

}
</script>

<style>

</style>
